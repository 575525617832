<template>
  <section>
    <div id="airport-slot-component">
      <b-card>
        <div class="d-flex align-items-center">
          <h1 class="my-2 mr-1">
            {{
              otherLegInformations.otherLegType === 'arrival' ? $t('trip.event.departure') : $t('trip.event.arrival')
            }}
            - {{ $t('timeline.validate_airport.airport_of') }} {{ markerInfo.name }}
          </h1>
          <b-badge :variant="`light-${resolveStatusVariantAndIcon(status).variant}`">
            {{
              status === 'Fluid' ? $t('timeline.validate_airport.airport_infos.slot_not_required') : $t('timeline.validate_airport.airport_infos.slot_required')
            }}
          </b-badge>
        </div>
        <b-row class="match-height">
          <!-- AIRPORT INFOS -->
          <b-col cols="12" md="4">
            <b-overlay :show="airportLoading" spinner-medium>
              <b-card v-if="!airportLoading" class="border-primary airport-info-wrapper">
                <div>
                  <h4 class="text-center">{{
                    $t('timeline.validate_airport.airport_infos.airport_identification_title')
                  }}</h4>

                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.icao')
                    }} : {{ airport.icaoCode }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.iata') }} : </span>
                    <app-not-specified-default :text="airport.iataCode" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.faa_code')
                    }} : </span>
                    <app-not-specified-default :text="airport.faaCode" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.airport_name')
                    }} : {{ airport.name }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{ $t('address.city') }} : {{ airport.city }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.state_subdivision') }} : </span>
                    <app-not-specified-default :text="airport.stateSubdivision" />
                  </b-card-text>

                  <h4 class="text-center">
                    {{ $t('timeline.validate_airport.airport_infos.airport_location_and_contacts_title') }}</h4>

                  <b-card-text class="mb-2">
                    <span class="font-weight-bold">{{ $t('common.country') }} : </span>
                    <span>{{ airport.country }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.website') }} : </span>
                    <app-not-specified-default :text="airport.website" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.public_email')
                    }} : </span>
                    <app-not-specified-default :text="airport.publicEmail" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.airport_type')
                    }} : </span>
                    <app-not-specified-default :text="airport.airportType" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.distance')
                    }} : </span>
                    <app-not-specified-default :text="airport.distanceFromCity" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{ $t('common.latitude') }} : {{ airport.latitude }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{ $t('common.longitude') }} : {{ airport.longitude }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.elevation')
                    }} : </span>
                    <app-not-specified-default :text="airport.elevation" />
                    {{ $getMeasurementUnitItn('m') }}
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.variation')
                    }} : </span>
                    <app-not-specified-default :text="airport.variation" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.timezone_lag')
                    }} : </span>
                    <app-not-specified-default :text="airport.timezoneLag" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.daylight_savings') }} : </span>
                    <app-not-specified-default :text="airport.daylightSavings" />
                  </b-card-text>

                  <h4 class="text-center">{{
                    $t('timeline.validate_airport.airport_infos.airport_services_title')
                  }}</h4>

                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.fuel_type')
                    }} : </span>
                    <span>{{ airport.fuelType }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.airport_of_entry') }} : </span>
                    <app-not-specified-default :text="airport.airportOfEntry" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.customs') }} : </span>
                    <span>{{ airport.customs ? $t('common.yes') : $t('common.no') }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.usga_statuts_desig_intl')
                    }} : </span>
                    <span>{{ airport.usgaStatutsDesigIntl ? $t('common.yes') : $t('common.no') }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.usga_statuts_landing_rights')
                    }} : </span>
                    <span>{{ airport.usgaStatutsLandingRights ? $t('common.yes') : $t('common.no') }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.usga_statuts_user_fees')
                    }} : </span>
                    <span>{{ airport.usgaStatutsUserFees ? $t('common.yes') : $t('common.no') }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.handling_mandatory')
                    }} : </span>
                    <app-not-specified-default :text="airport.handlingMandatory" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.slot_required')
                    }} : </span>
                    <span>{{ airport.congested ? $t('common.yes') : $t('common.no') }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.open24_hours')
                    }} : </span>
                    <span>{{ airport.open24Hours ? $t('common.yes') : $t('common.no') }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.control_tower_hours')
                    }} : </span>
                    <app-not-specified-default :text="airport.controlTowerHours" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.airport_hours')
                    }} : </span>
                    <app-not-specified-default :text="airport.airportHours" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.airport_aftn')
                    }} : </span>
                    <app-not-specified-default :text="airport.airportAFTN" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.airport_sita')
                    }} : </span>
                    <app-not-specified-default :text="airport.airportSITA" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.fire_category')
                    }} : </span>
                    <app-not-specified-default :text="airport.fireCategory" />
                  </b-card-text>

                  <h4 class="text-center">
                    {{ $t('timeline.validate_airport.airport_infos.airport_runway_and_landing_aids_title') }}</h4>

                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.approach_list')
                    }} : </span>
                    <span>{{ airport.approachList }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.primary_runway_id') }} : </span>
                    <span>{{ airport.primaryRunwayId }}</span>
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.runway_length')
                    }} : </span>
                    <app-not-specified-default
                      :text="airport.runwayLength ? airport.runwayLength + $getMeasurementUnitItn('m') : null"
                    />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.runway_width')
                    }} : </span>
                    <app-not-specified-default
                      :text="airport.runwayWidth ? airport.runwayWidth + $getMeasurementUnitItn('m') : null"
                    />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{ $t('timeline.validate_airport.airport_infos.pcn') }} : </span>
                    <app-not-specified-default :text="airport.pcn" />
                  </b-card-text>
                  <b-card-text class="mb-1">
                    <span class="font-weight-bold">{{
                      $t('timeline.validate_airport.airport_infos.lighting')
                    }} : </span>
                    <span>{{ airport.lighting }}</span>
                  </b-card-text>
                </div>
              </b-card>
            </b-overlay>
          </b-col>

          <!--SLOT SELECTOR-->
          <b-col cols="12" md="8">
            <!--CONGESTED WITHOUT CALENDAR-->
            <b-card
              v-if="airport && airport.congested"
              class="border-primary"
            >
              <div class="ml-auto text-right">
                <b-button v-ripple.400 class="mb-1 btn" @click="goToOtherLegEvent">
                  <font-awesome-icon class="mr-50" icon="plane" />
                  {{
                    otherLegInformations.otherLegType === 'arrival' ? $t('trip.action.edit_airport_or_eta') : $t('trip.action.edit_airport_or_etd')
                  }}
                </b-button>
              </div>
              <b-card-title>
                <span>{{ $t('trip.validate_airport.slot.slot_selection') }} - {{
                  otherLegInformations.otherLegType === 'arrival' ? $t('trip.event.departure') : $t('trip.event.arrival')
                }}</span>
              </b-card-title>
              <b-form-group :label="$t('trip.validate_airport.slot.date')" class="mb-1" label-for="slot-date">
                <flat-pickr
                  id="slot_date"
                  v-model="dateSlotSelected"
                  :config="{
                    altInput: true,
                    altFormat: 'D, F J Y',
                    dateFormat: 'Y-m-d',
                    localize: $i18n.locale,
                  }"
                  :disabled="pprBase64Loading"
                  class="form-control"
                />
              </b-form-group>

              <div class="row">
                <div class="col-12">
                  <p class="font-weight-bold">{{ $t('trip.validate_airport.slot.required_time_range') }} *</p>
                </div>
              </div>

              <div class="row mt-1 mb-1">
                <div class="col-6">
                  <b-form-group
                    :label="$t('trip.validate_airport.slot.from_utc')"
                    label-cols="12"
                    label-cols-md="3"
                  >
                    <flat-pickr
                      v-model="timeSlotSelected"
                      :config="{
                        enableTime: true,
                        allowInput: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        localize: $i18n.locale,
                        time_24hr: true,
                      }"
                      :disabled="pprBase64Loading"
                      class="form-control"
                    />
                    <span v-if="!airportLoading">
                      {{ $t('common.local_time') }} :
                      {{
                        $moment
                          .utc(dateSlotSelected + ' ' + timeSlotSelected)
                          .utcOffset(airport.timeZone)
                          .format('ddd, MMM Do YYYY HH:mm')
                      }}
                    </span>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    :label="$t('trip.validate_airport.slot.to_utc')"
                    label-cols="12"
                    label-cols-md="3"
                  >
                    <flat-pickr
                      v-model="endTimeSlotSelected"
                      :config="{
                        enableTime: true,
                        allowInput: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        localize: $i18n.locale,
                        time_24hr: true,
                      }"
                      :disabled="pprBase64Loading"
                      class="form-control"
                    />
                    <span v-if="!airportLoading">
                      {{ $t('common.local_time') }} :
                      {{
                        $moment
                          .utc(dateSlotSelected + ' ' + endTimeSlotSelected)
                          .utcOffset(airport.timeZone)
                          .format('ddd, MMM Do YYYY HH:mm')
                      }}
                    </span>
                  </b-form-group>
                </div>
              </div>
              <div>
                <b-overlay :show="pprBase64Loading">
                  <b-button :disabled="pprBase64Loading" block class="mt-1" size="lg" variant="success" @click="confirmSlot()">
                    <span v-if="otherLegInformations.otherLegType === 'departure'">{{ $t('action.validate') }}</span>
                    <span v-else>{{ $t('trip.validate_airport.slot.validate_departure_arrival') }}</span>
                  </b-button>
                </b-overlay>
              </div>
              <!--CONGESTED WITH CALENDAR-->
              <b-overlay :show="slotLoading" class="mt-1" spinner-medium>
                <div v-if="calendarOptions.events.length > 0" class="app-calendar overflow-hidden border">
                  <div class="row no-gutters">
                    <!-- Calendar -->
                    <div class="col position-relative">
                      <div class="card shadow-none border-0 mb-0 rounded-0">
                        <b-overlay :show="slotLoading" spinner-medium>
                          <div class="card-body pb-0">
                            <full-calendar
                              v-if="$can('TRIP_SLOT_MANAGEMENT_VIEW')"
                              ref="calendarSlot"
                              :options="calendarOptions"
                              :plugins="calendarOptions.plugins"
                              class="full-calendar"
                            />
                          </div>
                        </b-overlay>
                      </div>
                    </div>
                  </div>
                </div>
              </b-overlay>
            </b-card>
            <!--VALIDATE FLUID SLOT-->
            <b-card v-else class="border-primary">
              <!-- SWITCH AIRPORT -->
              <div class="ml-auto text-right">
                <b-button v-ripple.400 class="mb-1 btn" @click="goToOtherLegEvent">
                  <font-awesome-icon class="mr-50" icon="plane" />
                  {{
                    otherLegInformations.otherLegType === 'arrival' ? $t('trip.action.edit_airport_or_eta') : $t('trip.action.edit_airport_or_etd')
                  }}
                </b-button>
              </div>
              <b-card-title>
                <span>{{ $t('trip.validate_airport.slot.slot_available') }} - {{
                  otherLegInformations.otherLegType === 'arrival' ? $t('trip.event.departure') : $t('trip.event.arrival')
                }}</span>
              </b-card-title>
              <b-row>
                <!-- DATE -->
                <b-col cols="12" md="6">
                  <b-form-group :label="$t('trip.validate_airport.slot.date')" class="mb-1" label-for="date">
                    <flat-pickr
                      id="date"
                      v-model="dateSlotSelected"
                      :config="{
                        altInput: true,
                        altFormat: 'D, F J Y',
                        dateFormat: 'Y-m-d',
                        localize: $i18n.locale,
                      }"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <!-- HEURE + LOCAL TIME -->
                <b-col cols="12" md="6">
                  <b-form-group :label="$t('trip.validate_airport.slot.time_utc')" class="mb-1" label-for="start-hour">
                    <flat-pickr
                      id="start-hour"
                      v-model="timeSlotSelected"
                      :config="{
                        enableTime: true,
                        allowInput: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        localize: $i18n.locale,
                        time_24hr: true,
                      }"
                      class="form-control"
                    />
                  </b-form-group>
                  <span v-if="!airportLoading">
                    {{ $t('common.local_time') }} :
                    {{
                      $moment
                        .utc(dateSlotSelected + ' ' + timeSlotSelected)
                        .utcOffset(airport.timeZone)
                        .format('ddd, MMM Do YYYY HH:mm')
                    }}
                  </span>
                </b-col>
              </b-row>
              <div>
                <b-overlay :show="pprBase64Loading">
                  <b-button :disabled="pprBase64Loading" block class="mt-1" size="lg" variant="success" @click="confirmSlot()">
                    <span v-if="otherLegInformations.otherLegType === 'departure'">{{ $t('action.validate') }}</span>
                    <span v-else>{{ $t('trip.validate_airport.slot.validate_departure_arrival') }}</span>
                  </b-button>
                </b-overlay>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </section>
</template>

<script>
import { flatten } from 'lodash'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import momentPlugin from '@fullcalendar/moment'
import interactionPlugin from '@fullcalendar/interaction'

import UIHelper from '@/helpers/ui'
import { patchTripStepValidateAirportRequest } from '@/request/globalApi/requests/tripStepRequests'
import { fetchAirportRequest } from '@/request/globalApi/requests/airportRequests'
import { fetchTripStepAirportRequest } from '@/request/globalApi/requests/tripStepEventRequests'
import AppNotSpecifiedDefault from '@/components/AppNotSpecifiedDefault.vue'
import TimelineStepFlightPpr from "@/views/timeline/step/TimelineStepFlightPpr.vue";

export default {
  name: 'AirportSlot',

  components: {
    TimelineStepFlightPpr,
    FullCalendar,
    AppNotSpecifiedDefault,
  },
  mixins: [UIHelper],
  props: {
    trip: {
      type: Object,
      default: () => ({}),
    },
    tripStep: {
      type: Object,
      default: () => ({}),
    },
    tripLeg: {
      type: Object,
      default: () => ({}),
    },
    markerInfo: {
      type: Object,
      default: () => ({}),
    },
    otherLegInformations: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isMounted: false,
      debouncePpr: null,
      pprBase64Loading: false,
      pprBase64: null,
      status: 'Fluid',
      airport: null,
      scrollAllowed: true,
      airportLoading: true,
      slotLoading: true,
      dateSlotSelected: '',
      timeSlotSelected: '',
      endTimeSlotSelected: '',
      calendarOptions: {
        timeZone: 'UTC',
        plugins: [momentPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: this.defaultCalendarView(),
        allDaySlot: false,
        headerToolbar: {
          start: 'sidebarToggle, title',
          end: 'timeGridWeek,listMonth',
        },
        slotDuration: '00:05:00',
        slotLabelInterval: 5,
        events: [],
        selectable: true,
        eventClick: currentSlot => {
          if (Number(currentSlot.event.constraint) > 0) {
            this.confirmSlot(currentSlot)
          }
        },
        eventResizableFromStart: true,
        dragScroll: true,
        navLinks: true,
        eventTimeFormat: {
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
          meridiem: false,
        },
        slotLabelFormat: {
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
          meridiem: false,
        },
        dayHeaderFormat: 'dd, DD MMM',
        locale: this.$i18n.locale,
        firstDay: 1,
        viewDidMount: () => {
          const calendarApi = this.$refs.calendarSlot.getApi()
          calendarApi.gotoDate(
            this.otherLegInformations.otherLegDateTime
              ? this.$moment(this.otherLegInformations.otherLegDateTime).format('YYYY-MM-DD')
              : this.$moment(this.dateSlotSelected).format('YYYY-MM-DD'),
          )
        },
      },
    }
  },
  watch: {
    dateSlotSelected(val) {
      if (this.airport && this.airport.congested) {
        this.fetchAirportSlots(this.markerInfo.id, val)
      }

      this.tripLeg.startsAt = this.$moment.utc(`${this.dateSlotSelected} ${this.timeSlotSelected}`, 'YYYY-MM-DD HH:mm')
    },
    endTimeSlotSelected() {
      this.tripLeg.endsAt = this.$moment.utc(`${this.dateSlotSelected} ${this.endTimeSlotSelected}`, 'YYYY-MM-DD HH:mm')
    },
    timeSlotSelected() {
      this.tripLeg.startsAt = this.$moment.utc(`${this.dateSlotSelected} ${this.timeSlotSelected}`, 'YYYY-MM-DD HH:mm')
    },
    markerInfo() {
      this.scrollAllowed = true
      this.$nextTick(() => {
        this.getInfos(this.markerInfo.id)
        this.scrollToInfos()
      })
    },
  },
  mounted() {
    this.getInfos(this.markerInfo.id)
    if (!this.tripLeg.allowedToSeePpr) {
      this.pprBase64Loading = false
    }
    this.isMounted = true
  },
  updated() {
    if (this.scrollAllowed) {
      this.scrollToInfos()
    }
  },
  methods: {
    defaultCalendarView() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'listWeek' : 'timeGridWeek'
    },
    getInfos(id) {
      this.airportLoading = true
      fetchAirportRequest(id)
        .then(response => {
          this.airport = response.data
          if (response.data.congested) {
            this.status = 'Slot required'
            this.fetchAirportSlots(id, this.otherLegInformations && this.otherLegInformations.otherLegDateTime ? this.otherLegInformations.otherLegDateTime : this.tripLeg.eventDateTime)
          } else {
            this.status = 'Fluid'
            this.slotLoading = false
          }

          if (!this.otherLegInformations || !this.otherLegInformations.otherLegId) {
            this.otherLegInformations = {
              otherLegType: this.$route.params.other_leg_type,
              otherLegId: this.$route.params.other_leg_id,
            }
          }
        })
        .finally(() => {
          this.airportLoading = false
        })
      this.timeSlotSelected = this.$moment(this.tripLeg.eventDateTime).utc().format('HH:mm')
      this.dateSlotSelected = this.$moment(this.tripLeg.eventDateTime).format('YYYY-MM-DD')
      let endDate = this.tripLeg.eventDateTime
      if (this.tripLeg.endsAt) {
        endDate = this.tripLeg.endsAt
      }

      this.endTimeSlotSelected = this.$moment(endDate).utc().format('HH:mm')
    },
    fetchAirportSlots(id, date) {
      if (this.$can('TRIP_SLOT_MANAGEMENT_VIEW')) {
        this.slotLoading = true
        this.calendarOptions.events = []
        // Leg = Event dans ce composant
        fetchTripStepAirportRequest(this.$route.params.leg_id, id, { estimatedDate: date })
          .then(response => {
            if (response.data) {
              let slotId = 0
              this.calendarOptions.events = flatten(response.data.runwayAvailabilities.map(availability => availability.slots.map(slot => ({
                id: slotId++,
                constraint: slot.quantity,
                startTime: this.$moment(`${slot.startHours}:${slot.startMinutes}`, 'H:m').format('HH:mm'),
                endTime: this.$moment(`${slot.endHours}:${slot.endMinutes}`, 'H:m').format('HH:mm'),
                daysOfWeek: [availability.dayOfWeek === 7 ? 0 : availability.dayOfWeek],
                color: slot.availabilityColor,
                textColor: 'white',
                start: this.$moment(availability.date).format('YYYY-MM-DD'),
                title: slot.quantity,
                extendedProps: { slots: { percentage: 100 } },
              }))))
            }
          })
          .finally(() => {
            this.slotLoading = false
          })
      }
    },
    scrollToInfos() {
      setTimeout(() => {
        const el = document.getElementById('airport-slot-component')
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
          this.scrollAllowed = false
        }
      }, 200)
    },
    /* CALL API */
    postSlot(slot) {
      const eventId = this.$route.params.event_id
      const tripId = this.$route.params.trip_id
      const legId = this.otherLegInformations.otherLegId
      patchTripStepValidateAirportRequest(eventId, this.tripLeg.id, slot).then(() => {
        if (this.otherLegInformations.otherLegType === 'arrival') {
          this.$router.push({
            name: 'airport-map',
            params: {
              trip_id: tripId,
              event_id: eventId,
              leg_id: legId,
            },
          })
        } else {
          this.$router.push({
            name: 'trip-view',
            params: { trip_id: tripId },
          })
        }
      })
    },
    async confirmSlot(slotFromCalendar) {
      let slot = null
      let textDate = ''
      if (this.airport && this.airport.congested) {
        if (slotFromCalendar) {
          textDate = `${this.$moment.utc(slotFromCalendar.event.start).format('ddd, MMM Do YYYY')}
                    ${this.$t('timeline.validate_airport.slot_selection.from')} ${this.$moment.utc(slotFromCalendar.event.start).format('HH:mm')} ${this.$t('common.utc')}
                    ${this.$t('timeline.validate_airport.slot_selection.to')} ${this.$moment.utc(slotFromCalendar.event.end).format('HH:mm')} ${this.$t('common.utc')}`

          slot = {
            startsAt: slotFromCalendar.event.start,
            endsAt: slotFromCalendar.event.end,
            airportId: this.markerInfo.id,
            pprBase64: this.pprBase64,
          }
        } else {
          textDate = `${this.$moment.utc(this.dateSlotSelected).format('ddd, MMM Do YYYY')}
                    ${this.$t('timeline.validate_airport.slot_selection.from')} ${this.timeSlotSelected} ${this.$t('common.utc')}
                    ${this.$t('timeline.validate_airport.slot_selection.to')} ${this.endTimeSlotSelected} ${this.$t('common.utc')}`
          slot = {
            startsAt: this.$moment.utc(`${this.dateSlotSelected} ${this.timeSlotSelected}`, 'YYYY-MM-DD HH:mm'),
            endsAt: this.$moment.utc(`${this.dateSlotSelected} ${this.endTimeSlotSelected}`, 'YYYY-MM-DD HH:mm'),
            airportId: this.markerInfo.id,
            pprBase64: this.pprBase64,
          }
        }
      } else {
        textDate = `${this.$moment.utc(this.dateSlotSelected).format('ddd, MMM Do YYYY')}
                    ${this.$t('timeline.validate_airport.slot_selection.at')} ${this.timeSlotSelected} ${this.$t('common.utc')}`
        slot = {
          startsAt: this.$moment.utc(`${this.dateSlotSelected} ${this.timeSlotSelected}`, 'YYYY-MM-DD HH:mm'),
          endsAt: this.$moment.utc(`${this.dateSlotSelected} ${this.timeSlotSelected}`, 'YYYY-MM-DD HH:mm'), // End = Start en format "Slot not required"
          airportId: this.markerInfo.id,
          pprBase64: this.pprBase64,
        }
      }

      this.$swal({
        title: this.$t('alert.delete_confirmation.title'),
        text: `${this.$t('alert.timeline.validate_airport.slot_selected.message')}
      ${textDate}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('common.yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-dark ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // Test pour ouvrir la PPR dans un nouvel onglet pour visualiser le rendu
          // this.openPDF()
          this.postSlot(slot)
        }
      })
    },
    goToOtherLegEvent() {
      const eventId = this.$route.params.event_id
      const tripId = this.$route.params.trip_id
      const legId = this.otherLegInformations.otherLegId
      const routeData = this.$router.resolve({
        name: 'airport-map',
        params: {
          trip_id: tripId,
          event_id: eventId,
          leg_id: legId,
        },
      })
      window.open(routeData.href, '_blank')
    },
    openPDF() {
      // Base64 du PDF
      const pdfBase64 = this.pprBase64;

      // Créer une nouvelle fenêtre ou un nouvel onglet
      const newWindow = window.open('', '_blank');

      // Charger le contenu PDF dans la nouvelle fenêtre
      newWindow.document.write('<iframe src="data:application/pdf;base64,' + pdfBase64 + '" width="100%" height="100%"></iframe>');
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';

.airport-info-wrapper {
  max-height: 950px;
  overflow: auto;
}

.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-col-header {
  width: 100% !important;
}

.fc-selectDateBtn-button {
  margin-left: 2rem !important;
  border-color: #dcc181 !important;
  background-color: #dcc181 !important;
}

.fc-timegrid-event-harness:hover {
  z-index: 2 !important;
  height: 40px;

  .fc-event-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fc-event-time {
    display: none;
  }
}
</style>
